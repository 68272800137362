import React, { useEffect } from "react";
import './testReports.css'
import { Link } from "react-router-dom";

import allTestReports from "../../Comps/Products/testReports";

const TestLink = ({ tests }) => {
  const extractRange = (name) => { 
    const match = name.match(/\((\d+\.?\d*)mg - (\d+\.?\d*)mg\)/i); 
    console.log(match)
    if (match) { 
      const start = parseFloat(match[1]); 
      const end = parseFloat(match[2]); 
      return { start, end }; 
    } 
    return null
  }

  const calculatePercentageDifference = (start, end) => { 
    return ((end - start) / start) * 100; 
  };

  const range = extractRange(tests.name)
  const percentageDifference = range ? calculatePercentageDifference(range.start, range.end).toFixed(2) : null

  return (
    <div className="testing-link-divs">
      <Link className="testing-links" to={tests.reportLink} target="_blank">
        <p>{tests.name}
          <span 
            className={percentageDifference >= 10 || percentageDifference <= -10 ? "testing-links-span color-red" : "testing-links-span"}
          >{
            ` ${percentageDifference > 0 
              ? `+${percentageDifference}` 
              : percentageDifference}%`
            }
          </span>
        </p>
      </Link>
    </div>
  )
}

const TestReports = () => {
  const totalWithin10 = allTestReports.filter(item => item.within10).length;
  const totalAbove10 = allTestReports.filter(item => !item.within10).length;

  return (
    <div className="test-reports-main">
      {/* Heading */}
      <div className="test-reports-heading">
        <h1>Test Reports</h1>
      </div>
      <div className="lower-test-report-div">
        <div>
          <h3>{`(${totalWithin10}) Within 10%`}</h3>
          {allTestReports.filter((items) => items.within10).map((tests) => (
            <TestLink key={tests.reportLink} tests={tests} />
          ))}
        </div>
        <hr/>
        <div>
          <h3>{`(${totalAbove10}) Outside 10%`}</h3>
          {allTestReports.filter((items) => !items.within10).map((tests) => (
            <TestLink key={tests.reportLink} tests={tests} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default TestReports;