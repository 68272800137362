import React, { useEffect, useState } from "react";
import './postCheckout.css';
import { Link } from "react-router-dom";
import Spinner from "../../../Comps/Extras/loadingIcon";

import vileOils from '../../../images/vile v2.png'
import pillBottleOrals from '../../../images/pill container v2.png'
import productsBackend from "../../../Comps/Products/productBackend";
import LoadingText from "../../../Comps/Extras/loadingText";

const StatusQuestionsModal = ({ onClose }) => {

  return (
    <div className="post-checkout-sec-3">
      <div className="post-checkout-sec-3-content">
        <h2>Recieved:</h2>
        <p>
          Your order has been submitted and recorded.  We are working on verifying your payment, this could take up to 48 hours to complete.  
        </p>
        <h2>Confirmed:</h2>
        <p>
          Your payment has been confirmed.  We will send you confirmation with tracking once your order ships!
        </p>
        <h2>Shipped:</h2>
        <p>
          We shipped your order!  Check the tracking info we sent to get more info!
        </p>
        <h2>Questions?:</h2>
        <p>
          Contact Us: <Link to='mailto:Olympicpharma@proton.me'>Olympicpharma@proton.me</Link>
        </p>
        <button
          id="post-checkout-sec-3-close-btn"
          className="post-checkout-sec-3-close-btn"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
    </div>
  )
}

const PostCheckout = () => {
  const [orderNumber, setOrderNumber] = useState(localStorage.getItem('jhlltd-order-num'));
  const [orderStatusInfo, setOrderStatusInfo] = useState("")
  const [orderItems, setOrderItems] = useState(false)
  const [currentUserInfo, setuserInfo] = useState(false)
  const [currentErrorCode, changeCurrentErrorCode] = useState(false)
  const [currentOrderStatusNum, changeCurrentOrderStatusNum] = useState({
    orderStatusNum: localStorage.getItem('jhlltd-order-num') || '',
  })

  const changeOrderStatusNum = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    changeCurrentOrderStatusNum({
      [name]: value
    })
  }

  const clearOrderStatusNum = (e) => {
    e.preventDefault();
    changeCurrentOrderStatusNum({
      orderStatusNum: ''
    })
    localStorage.removeItem('jhlltd-order-num')
  } 

  useEffect(() => {
    const fetchOrderStatus = async () => {
      try{
        const response = await fetch(`/api/order-status/${orderNumber}`);
        const data = await response.json();
        console.log(data)
        if (data.error) {
          setOrderStatusInfo({ error: data.error });
        } else {
          setOrderStatusInfo(data);
        }
      } catch (error) {
        console.error('Error fetching order status:', error);
        setOrderStatusInfo({ error: 'Failed to fetch order status' });
      }
    }
    const fetchOrderItems = async () => {
      try{
        const response = await fetch(`/api/order-items/${orderNumber}`);
        const data = await response.json();
        console.log(data)
        if (data.error) {
          setOrderItems(false);
        } else {
          setOrderItems(data);
        }
      } catch (error) {
        console.error('Error fetching Order Items:', error);
        setOrderItems({ error: 'Failed to fetch Order Items' });
      }
    }
    const fetchUserInfo = async () => {
      try{
        const response = await fetch(`/api/user-info/${orderNumber}`);
        const data = await response.json();
        console.log(data)
        if (data.error) {
          setuserInfo(false);
        } else {
          console.log(data)
          setuserInfo(data);
        }
      } catch (error) {
        console.error('Error fetching User Data:', error);
        setuserInfo({ error: 'Failed to fetch User Data' });
      }
    }

    fetchOrderStatus()
    fetchOrderItems()
    fetchUserInfo()
  }, [orderNumber])

  const clearLocalOrder = () => {
    setOrderNumber('')
    localStorage.removeItem('jhlltd-order-num')
    localStorage.removeItem('jhlltd-order-num-pmt')
  }

  const setLocalOrder = async (event) => {
    event.preventDefault();
    const orderNumber = currentOrderStatusNum.orderStatusNum.trim();
    
      const response = await fetch(`/api/payment-method/${orderNumber}`)
      const data = await response.json();
      console.log(data)
      if (data.error) {
        console.error('Not a correct order number')
        changeCurrentErrorCode("Order Number Not Found!")
      } else {
        if(currentErrorCode) {
          changeCurrentErrorCode(false)
        }
        if (data === "Ethereum") {
          localStorage.setItem('jhlltd-order-num-pmt', 'eth');
          localStorage.setItem('jhlltd-order-num', orderNumber);
          setOrderNumber(orderNumber);
        } else if (data === "Bitcoin") {
          localStorage.setItem('jhlltd-order-num-pmt', 'btc');
          localStorage.setItem('jhlltd-order-num', orderNumber);
          setOrderNumber(orderNumber);
        }
      }
  };  


  const [stateStatusInfoModal, changeStateStatusInfoModal] = useState(false);

  const toggleStatusModal = () => {
    changeStateStatusInfoModal(!stateStatusInfoModal)
    console.log(stateStatusInfoModal)
  }

  return (
    <div className="post-checkout-main-status">
    {orderNumber ? (
      <div className="post-checkout-main-div1">
      {stateStatusInfoModal ? 
      <StatusQuestionsModal
        onClose={() => changeStateStatusInfoModal(false)}
      /> : ''}
        <div className="post-checkout-sec-1">
          <div>
            <h1>Order Status</h1>
            <h2>Order Number: <span>{orderNumber}</span></h2>
            <button onClick={clearLocalOrder}>Change Order Number</button>
          </div>
        </div>
        <hr/>
        <div className="post-checkout-sections">
            
          <div className="post-checkout-all-info">
            <div className="post-checkout-all-info-top">
              <div className="post-checkout-all-info-top-status">
                {!orderStatusInfo ? (
                  <div className="spinner-post-checkout">
                    <Spinner/>
                    <h2>Loading Status...</h2>
                  </div>
                ) : (
                  orderStatusInfo ? (
                  <div className="order-status-status-div">
                    <h2><span>{orderStatusInfo === "Received" ? "🟢" : "🔴"}</span>Received</h2>
                    <h2><span>{orderStatusInfo === "Confirmed" ? "🟢" : "🔴"}</span>Confirmed</h2>
                    <h2><span>{orderStatusInfo === "Shipped" ? "🟢" : "🔴"}</span>Shipped</h2>
                    <div>
                      <button onClick={() => toggleStatusModal()}>What These Mean</button>
                    </div>
                  </div>
                  ) : <LoadingText/>
                )}
              </div>
              <div className="post-checkout-all-info-top-info">
                <h2>User Information</h2>
                {currentUserInfo ? (
                    <div className="post-checkout-all-info-top-info-div">
                      <div className="post-order-item-sections">
                        <h3>Name:</h3>
                        <p>{currentUserInfo.customerFName && currentUserInfo.customerLName ? `${currentUserInfo.customerFName} ${currentUserInfo.customerLName}` : <LoadingText/>}</p>
                      </div>
                      <div className="post-order-item-sections">
                        <h3>Email:</h3>
                        <p>{currentUserInfo.customerEmail ? `${currentUserInfo.customerEmail}` : <LoadingText/>}</p>
                      </div>
                      <div className="post-order-item-sections">
                        <h3>Country:</h3>
                        <p>{currentUserInfo.customerCountry ? `${currentUserInfo.customerCountry}` : <LoadingText/>}</p>
                      </div>
                    </div>
                ) : (
                  <div>
                    <Spinner/>
                    <h2>Loading User...</h2>
                  </div>
                )}
              </div>
            </div>
            <hr/>
            <div className="post-checkout-all-info-bottom">
              <div className="post-checkout-all-info-bottom-title">
                <h2>Order Items</h2>
              </div>
              <div className="post-checkout-all-info-bottom-bottom">
              <div className="post-order-items-div">
              {orderItems && orderItems.length > 1 ? (orderItems.map((items) => (
                    <div className="post-order-items-indiv-div">
                      <div className="post-order-item-title">
                        <h3>{items.name}</h3>
                      </div>
                      <div className="post-order-item-imgs">
                        <h3>{`$${items.price} x ${items.quantity} = $${items.price * items.quantity}`}</h3>
                        {productsBackend.filter(item => item.productBackendId === items.productBackendId).map(item => item.subCategory === 10 || item.subCategory === 11 || item.subCategory === 12 ? <img src={pillBottleOrals} alt='pillBottleOrals'/> : <img src={vileOils} alt='vileOils'/>)}
                      </div>
                      {items.carrierOil ? (
                      <div className="post-order-item-sections">
                        <h3>Carrier Oil:</h3>
                        <p>{items.carrierOil}</p>
                      </div>
                      ) : ''}
                      {items.volume ? (
                      <div className="post-order-item-sections">
                        <h3>Volume:</h3>
                        <p>{items.volume}</p>
                      </div>
                      ) : ''}
                      {items.concentration ? (
                      <div className="post-order-item-sections">
                        <h3>Concentration:</h3>
                        <p>{items.concentration}</p>
                      </div>
                      ) : ''}
                    </div>
                ))
              ) : orderItems && orderItems.length === 1 ? (
                  <div className="post-order-items-indiv-div">
                    <div className="post-order-item-title">
                      <h3>{orderItems[0].name}</h3>
                    </div>
                    <div className="post-order-item-imgs">
                        <h3>{`$${orderItems[0].price} x ${orderItems[0].quantity} = $${orderItems[0].price * orderItems[0].quantity}`}</h3>
                        {productsBackend.filter(item => item.productBackendId === orderItems[0].productBackendId).map(item => item.subCategory === 10 || item.subCategory === 11 || item.subCategory === 12 ? <img src={pillBottleOrals} alt='pillBottleOrals'/> : <img src={vileOils} alt='vileOils'/>)}
                      </div>
                    {orderItems[0].carrierOil ? (
                    <div className="post-order-item-sections">
                      <h3>Carrier Oil:</h3>
                      <p>{orderItems[0].carrierOil}</p>
                    </div>
                    ) : ''}
                    {orderItems[0].volume ? (
                    <div className="post-order-item-sections">
                      <h3>Volume:</h3>
                      <p>{orderItems[0].volume}</p>
                    </div>
                    ) : ''}
                    {orderItems[0].concentration ? (
                    <div className="post-order-item-sections">
                      <h3>Concentration:</h3>
                      <p>{orderItems[0].concentration}</p>
                    </div>
                    ) : ''}
                  </div>
              )
             : (
                <div>
                  <Spinner/>
                  <h2>Loading Items...</h2>
                </div>
              )}
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="order-not-found-div-main">
          <h1>No Order Number Found!</h1>
          <h2>Continue by giving us your order number</h2>
          <form className="order-num-form">
            <button
              onClick={clearOrderStatusNum}
              className="clear-order-status-btn"
            >
              ❌
            </button>
            <input 
              name="orderStatusNum"
              className="order-num-input"
              placeholder="Enter Order Number"
              type="text"
              value={currentOrderStatusNum.orderStatusNum}
              onChange={changeOrderStatusNum}
            />
            <button 
              className="order-num-button" 
              onClick={setLocalOrder}
            >
              Continue
            </button>
          </form>
          {currentErrorCode ? (
            <h4 className="status-error-display">{currentErrorCode}</h4>
          ) : ''}
          <div className="order-not-found-dm-email">
            <h2>Can't Find Your Order Number?</h2>
            <h2>▼Email Us!▼</h2>
            <Link to='mailto:Olympicpharma@proton.me'>
              <h2>{`Olympicpharma@proton.me`}</h2>
            </Link>
          </div>
        </div>
    )}
    </div>
  )
}

export default PostCheckout;