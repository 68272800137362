import React, { useEffect, useState } from "react";
import './restorePayment.css';
import { Link } from "react-router-dom";

const RestorePayment = () => {
  const [restoreOrderNum, changeRestoreOrderNum] = useState({
    orderNum: localStorage.getItem('jhlltd-order-num') || '',
  })

  const changeOrderNumInput = (e) => {
    const { name, value } = e.target;
    changeRestoreOrderNum({
      [name]: value
    })
  }

  const clearOrderNumInput = (e) => {
    changeRestoreOrderNum({
      orderNum: ''
    })
    localStorage.removeItem('jhlltd-order-num')
  } 

  const redirectToPmt = async (event) => {
    event.preventDefault();
    const orderNumber = restoreOrderNum.orderNum.trim();
    
      const response = await fetch(`/api/payment-method/${orderNumber}`)
      const data = await response.json();
      // console.log(data)
      if (data.error) {
        console.error('Not a correct order number')
        alert('Not a Correct Order Number')
      } else {
        if (data === "Ethereum") {
          localStorage.setItem('jhlltd-order-num-pmt', 'eth');
          localStorage.setItem('jhlltd-order-num', orderNumber);
            window.location.replace('/cart/checkout/eth');
        } else if (data === "Bitcoin") {
          localStorage.setItem('jhlltd-order-num-pmt', 'btc');
          localStorage.setItem('jhlltd-order-num', orderNumber);
            window.location.replace('/cart/checkout/btc');
        }
      }
  };  

  return (
    <div className="restore-pmt-main">
      <div>
        <h1>Finish Payment</h1>
      </div>
      <div>
        <div>
          <h3>Have the order number?</h3>
          <div className="restore-pmt-input-div">
            <button
              id="restore-clear-pmt-btn"
              onClick={clearOrderNumInput}
            >
              ❌
            </button>
            <input
              placeholder="Order Number"
              id="restore-pmt-input"
              name="orderNum" 
              required
              value={restoreOrderNum.orderNum}
              onChange={changeOrderNumInput}
            />
            <button
              id="restore-pmt-btn"
              onClick={redirectToPmt}
            >
              Continue
            </button>
          </div>
        </div>
        
        <br/>
        <hr/>

        <div className="restore-pmt-emailus">
          <h3>Don't know your Order Number?</h3>
          <h3>Email Us! <Link
            to='mailto:Olympicpharma@proton.me'
            className="restore-pmt-emailus-us"
          >
            Olympicpharma@proton.me
            </Link></h3>
        </div>

      </div>
    </div>
  );
};

export default RestorePayment;