import React, { useState, useEffect } from 'react';
import './loadingText.css';

const LoadingText = () => {
  const [loadingText, setLoadingText] = useState('Loading.');

  useEffect(() => {
    const texts = ['Loading.', 'Loading..', 'Loading...', 'Loading.'];
    let index = 0;

    const interval = setInterval(() => {
      setLoadingText(texts[index]);
      index = (index + 1) % texts.length;
    }, 100); // 100ms intervals

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <h3 className='loading-text-h3'>{loadingText}</h3>
  )
};

export default LoadingText;
